import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Modal, Stack, Typography } from '@mui/material';

import MainContainer from '../components/MainContainer';
import DocumentTitle from '../components/DocumentTitle';
import AppActionToolbar from '../components/AppActionToolbar';
import ScheduleUserList from '../components/Schedule/ScheduleUserList';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import TemporaryScheduleModalView from '../components/Schedule/TemporaryScheduleModalView';
import ChangeRequestsModalView from '../components/Schedule/ChangeRequestsModalView';
import Timezone from '../components/Util/Timezone';
import JobBreakPolicy from '../components/TeamSettings/JobDescription/JobBreakPolicy';

import { getWeekRange } from '../utils/date';
import { useGo2Members } from '../hooks/member';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { UserMetadata } from '../types/profile';
import { useUserProvider } from '../providers/user/user';

type SettingsAdminScheduleProps = {
  basePath?: string;
};

const SettingsAdminSchedule = ({ basePath }: SettingsAdminScheduleProps) => {
  const { current } = useWorkspaceProvider();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();

  const [isTemporaryScheduleOpen, setIsTemporaryScheduleOpen] = useState(false);
  const [isChangeRequestsOpen, setIsChangeRequestsOpen] = useState(false);
  const [changeRequestCounter, setChangeRequestCounter] = useState(0);

  const [range, setRange] = useState(() => [
    getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
      'YYYY-MM-DD',
    ),
    getWeekRange(moment().endOf('week').add(1, 'day')).end.format('YYYY-MM-DD'),
  ]);

  const isToday = moment();
  const isCurrentWeek =
    isToday.isSameOrAfter(range[0]) && isToday.isSameOrBefore(range[1]);

  const divisionGo2Members = useGo2Members({
    division: current!.id === 'my-squad' ? undefined : +current!.id,
    employee_ids: current!.id === 'my-squad' ? current!.memberId : undefined,
    limit: 300,
    is_active: true,
  });
  const userList = useMemo(() => {
    let usersMetadata: UserMetadata[] = [];
    divisionGo2Members.data?.results.forEach((item) => {
      usersMetadata.push(getUser('staff_id', +item.id)!);
    });
    return usersMetadata.filter((u) => !!u);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, divisionGo2Members.data]);

  const handlePrevWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).subtract(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleNextWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).add(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleOnResetDateToThisWeek = () => {
    setRange(() => [
      getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
        'YYYY-MM-DD',
      ),
      getWeekRange(moment().endOf('week').add(1, 'day')).end.format(
        'YYYY-MM-DD',
      ),
    ]);
  };

  useEffect(() => {
    if (divisionGo2Members.data) {
      let user_ids: number[] = [];
      let staff_ids: number[] = [];
      divisionGo2Members.data.results.forEach((item) => {
        staff_ids.push(+item.id);
        user_ids.push(+item.reports_to);
      });
      setUniqueIdsToFetch({ staff_ids, user_ids });
    }
    // eslint-disable-next-line
  }, [divisionGo2Members.data]);

  return (
    <>
      <DocumentTitle title='Schedule' trailingTitle='Builder' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Stack direction='row' gap={2} alignItems={'center'}>
            <M3IconButton
              onClick={handlePrevWeek}
              sx={{
                marginLeft: -2,
              }}
            >
              <ArrowLeftOutlinedIcon sx={{ left: -1, position: 'relative' }} />
            </M3IconButton>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(range[0]).format(
                'MMM D, YYYY',
              )} - ${moment(range[1]).format('MMM D, YYYY')}`}
              // sx={{ mx: 0.4 }}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240, pointerEvents: 'none' }}
            >
              {/* <DateRangeSelectionPopover
                startDate={dateForm.formState.start}
                endDate={dateForm.formState.end}
                onChange={({ start, end }) => {
                  dateForm.updateState((state) => ({ ...state, start, end }));
                }}
              /> */}
            </FilterColumnPanel>
            <M3IconButton onClick={handleNextWeek}>
              <ArrowLeftOutlinedIcon
                sx={{
                  left: 1,
                  transform: 'rotateZ(180deg)',
                  position: 'relative',
                }}
              />
            </M3IconButton>
            {!isCurrentWeek && (
              <M3Button onClick={handleOnResetDateToThisWeek}>
                This week
              </M3Button>
            )}
          </Stack>
          <Typography flex={1}></Typography>
          <Stack direction='row' gap={2} alignItems={'center'}>
            {!!current && current.id !== 'my-squad' && (
              <JobBreakPolicy divisionId={+current.id} />
            )}
            <M3Button
              color='secondary'
              variant='outlined'
              sx={{ mr: 1.5 }}
              onClick={() => setIsTemporaryScheduleOpen(true)}
            >
              <AddOutlinedIcon style={{ fontSize: 20, marginRight: 4 }} />
              Add Temporary Schedule&nbsp;
            </M3Button>
          </Stack>
        </AppActionToolbar>
        <Timezone />
        <br />
        <ScheduleUserList
          range={range}
          users={userList}
          changeRequestCounter={changeRequestCounter}
        />
        <Modal open={isTemporaryScheduleOpen}>
          <TemporaryScheduleModalView
            close={() => {
              setIsTemporaryScheduleOpen(false);
              setChangeRequestCounter((c) => ++c);
            }}
          />
        </Modal>
        <Modal open={isChangeRequestsOpen}>
          <ChangeRequestsModalView
            close={() => {
              setIsChangeRequestsOpen(false);
              setChangeRequestCounter((c) => ++c);
            }}
          />
        </Modal>
      </MainContainer>
    </>
  );
};

export default SettingsAdminSchedule;
