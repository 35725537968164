import { UseQueryOptions } from '@tanstack/react-query';
import { Go2MemberModel, MemberModel } from '../types/member';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import { AxiosRequestConfig } from 'axios';

export type UseMembersProps = ListQuery & {
  first_name?: string;
  last_name?: string;
  existing?: boolean;
  type?: 'go2_member' | 'partner_member';
  department?: number;
};
export function useMembers<R = MemberModel>(
  params: UseMembersProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseMembersProps>({
    params,
    baseIdentifier: 'useMembers',
    identifierKeys: ['search'],
    queryParamKeys: [
      'department',
      'first_name',
      'last_name',
      'existing',
      'type',
    ],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/admin/members/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseGo2MembersParams = ListQuery & {
  department?: number | null;
  division?: number | string | null;
  email?: string | null;
  employee_ids?: string | null;
  full_name?: string | null;
  is_active?: boolean | null;
  job_title?: string | null;
  reports_to?: string | number | null;
};
export function useGo2Members<R = Go2MemberModel>(
  params: UseGo2MembersParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseGo2MembersParams>({
    params,
    baseIdentifier: 'useGo2Members',
    identifierKeys: [
      'department',
      'division',
      'email',
      'employee_ids',
      'search',
      'reports_to',
    ],
    queryParamKeys: [
      'department',
      'division',
      'email',
      'employee_ids',
      'full_name',
      'is_active',
      'job_title',
      'reports_to',
    ],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/admin/go2-members/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseGo2MemberByIdParams = ListQuery & {
  staffId: string;
};
export function useGo2MemberById<R = Go2MemberModel>(
  params: UseGo2MemberByIdParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseGo2MemberByIdParams>({
    params,
    baseIdentifier: 'useGo2MemberById',
    identifierKeys: ['staffId'],
    queryParamKeys: ['staffId'],
  });

  return useQueryApi<ListQuery, R>(
    identifiers,
    `/admin/go2-members/${params.staffId}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export function usePostGo2MembersToggleTimeOffRequestAutoApprove(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<any, any>(
    `/admin/go2-members/toggle-timeoff-request-auto-approve/`,
    {},
    axiosConfig,
  );
}
