import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { M3TextField } from './M3/M3TextField';
import { M3Autocomplete } from './M3/M3Autocomplete';

import { useDivisionProvider } from '../providers/division/division';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useAppProvider } from '../providers/app/app';
import { getIsInAppDrawer } from './WorkspaceMenuList';

type Props = {
  onFocus?: () => void;
  onBlur?: () => void;
  close?: () => void;
};

const MenuDivisionDropdown = ({ onFocus, onBlur, close }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();
  let { current, setCurrent, items: workspaceItems } = useWorkspaceProvider();
  let { items } = useDivisionProvider();

  if (!current || !items.length) return null;

  const isInAppDrawer = getIsInAppDrawer(pathname);

  workspaceItems = workspaceItems.filter(
    (ws) => ws.type !== 'jumper_division_partner',
  );
  items = items.filter(
    (div) => !workspaceItems.some((ws) => `${ws.id}` === `${div.id}`),
  );

  return (
    <Box
      sx={{
        pt: 2,
        position: 'relative',
        '.MuiAutocomplete-popper': {
          '.MuiPaper-root': {
            ml: -2,
            mr: -2,
            mt: 1,
            borderTop: isDarkMode
              ? '1px solid var(--md-sys-color-surface-variant-dark)'
              : '1px solid #e0e0e1',
          },
        },
      }}
    >
      <M3Autocomplete
        disablePortal
        open
        options={items.map((item) => `${item.id}`)}
        value={
          current.type === 'jumper_division_partner' ? `${current.id}` : ''
        }
        onChange={(evt, value) => {
          const item = items.find((item) => `${item.id}` === value)!;
          if (item) {
            setCurrent({
              id: item.id,
              name: item.name,
              type: 'jumper_division_partner',
            });
            /**
             * Check if it's not in app drawer we navigate
             */
            if (!isInAppDrawer) {
              navigate(`/manifest/${item.id}`);
            }
          }
        }}
        getOptionLabel={(option) => {
          const item = items.find(
            (item) => `${item.id}` === (option as string),
          );
          return item ? item.name : '';
        }}
        renderInput={(params) => (
          <M3TextField
            {...params}
            label='Select a Division'
            placeholder='Select...'
            InputLabelProps={{
              focused: true,
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            fullWidth
            sx={{
              ml: 2,
              mr: 2,
            }}
          />
        )}
        sx={{
          width: 240,
          '.MuiPaper-root': {
            borderRadius: 0,
            boxShadow: 'none',
          },
        }}
      />
    </Box>
  );
};

export default MenuDivisionDropdown;
