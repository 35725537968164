import {
  Stack,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  SxProps,
  Tooltip,
} from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';
import { useForm } from '../BasicForm';
import { M3TextField } from '../M3/M3TextField';
import { M3Autocomplete } from '../M3/M3Autocomplete';
import TimePicker, { useHrMinOptions } from '../TimePicker/TimePicker';
import BasicDateRangePicker from '../Pickers/BasicDateRangePicker';

import { UserMetadata, UserSearchItemResponse } from '../../types/profile';
import { useAppProvider } from '../../providers/app/app';
import { getTimeDifferenceBetweenStartAndEnd } from '../../utils/date';
import { applyOpacityOnColor } from '../../utils/color';
import { IterableObject } from '../../types/types';
import { useCurrentProfile, useUserSearch } from '../../hooks/profile';
import { getUserProfileDisplayName } from '../../utils/user';
import {
  UsePostChangeRequestSchedulePayload,
  UsePostChangeRequestTemporarySchedulePayload,
  usePermanentTemporarySchedules,
  usePostChangeRequestSchedule,
  usePostChangeRequestTemporarySchedule,
  useUserPermanentSchedules,
} from '../../hooks/go2-schedules';
import {
  convertJsDayToPyDay,
  getLatestProperLocalSchedules,
} from '../../utils/schedule';
import { useUserProvider } from '../../providers/user/user';
import { getRawCodeMessage } from '../../utils/response';
import { DayScheduleLocal } from './ScheduleUserList';
import { useTimezone } from '../Util/Timezone';
import { DailyScheduleItem } from '../../types/schedules';
import { getUserFilteredDayLocalSchedules } from './ScheduleUserList/ScheduleCard';

type TemporaryScheduleModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    end?: string;
    start?: string;
    fromDate?: string;
    toDate?: string;
    localDate?: string;
    user?: UserMetadata | null;
    scheduleId?: number;
    isUserLock?: boolean;
    isDeleteOnly?: boolean;
    tempSchedulesAssociated?: DayScheduleLocal[];
    onSubmit?: (data: SubmitPayload) => void;
  };

type SubmitPayload = UsePostChangeRequestTemporarySchedulePayload[0];
export type TemporaryScheduleSubmitPayload = SubmitPayload;

type TimeValidFormState = {
  time?: string;
  valid: boolean;
};

type FieldSearchResponse = {
  id: number | string;
  name: string;
};

type FormState = IterableObject & {
  user: string;
  user_id?: number | null;
  requested_by: string;
  requested_by_role: 'teammate' | 'manager' | null;
  reason: string;
};

export function TemporaryScheduleModalViewBase({
  start: initialStartTime,
  end: initialEndTime,
  fromDate: initialFromDate,
  toDate: initialToDate,
  localDate: initialLocalDate,
  close,
  onSubmit,
  isUserLock,
  user: defaultUserMetadata,
  isDeleteOnly,
  scheduleId,
  tempSchedulesAssociated,
}: TemporaryScheduleModalViewProps) {
  const {
    isDarkMode,
    palettes: { main: palette },
    updateActionKeyCounter,
  } = useAppProvider();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();
  const { data: currentProfile } = useCurrentProfile();
  const time12hrFormat = 'hh:mm a';
  const time24hrFormat = 'HH:mm';

  const { enqueueSnackbar } = useSnackbar();

  const {
    formKeyCount,
    formState,
    hasChanged,
    handleChange,
    updateState: updateFormState,
    updateFormKeyCount,
  } = useForm<FormState>({
    user: '',
    user_id: null,
    requested_by: '',
    requested_by_role: null,
    reason: '',
  });
  const userData = getUser('user_id', formState.user_id);
  const timezone = useTimezone();
  // const userReportToData = getUser('user_id', userData?.reports_to_id);

  const searchSelect =
    (s: string) =>
    (data: UserSearchItemResponse[]): UserSearchItemResponse[] => {
      if (
        getUserProfileDisplayName(currentProfile!).fullName?.includes(
          s.toLowerCase(),
        ) ||
        currentProfile?.email.includes(s.toLowerCase())
      ) {
        data = [
          ...data,
          {
            id: `${currentProfile!.id}`,
            first_name: currentProfile!.first_name,
            last_name: currentProfile!.last_name,
            email: currentProfile!.email,
            photo_url: currentProfile!.photo_url,
            preferred_name: currentProfile!.preferred_name,
          },
        ];
      }

      return data;
    };

  const requesterSearch = useUserSearch(
    {
      key: 'requester',
      s: formState.user || 'a',
    },
    {
      select: searchSelect(formState.user) as () => UserSearchItemResponse[],
    },
  );

  const requestByOptions = useMemo(() => {
    const opts = [
      {
        id: 'teammate',
        label: 'Teammate',
      },
    ];

    if (+formState.user_id! !== currentProfile!.id) {
      opts.push({
        id: 'manager',
        label: 'Manager',
      });
    }

    return opts;
  }, [formState.user_id, currentProfile]);

  const dateForm = useForm<{ from?: string | null; to?: string | null }>({
    from: isDeleteOnly ? initialLocalDate ?? initialFromDate : null,
    to: isDeleteOnly ? initialLocalDate ?? initialFromDate : null,
  });
  const dateFormStateMemoized = useMemo(() => {
    return {
      from: dateForm.formState.from ? moment(dateForm.formState.from) : null,
      to: dateForm.formState.to ? moment(dateForm.formState.to) : null,
    };
  }, [dateForm.formState]);

  const startForm = useForm<TimeValidFormState>({
    time: initialStartTime,
    valid: !!initialStartTime,
  });

  const endForm = useForm<TimeValidFormState>({
    time: initialEndTime,
    valid: !!initialEndTime,
  });

  const [selectedDates, setSelectedDates] = useState<IterableObject<boolean>>(
    {},
  );
  const [selectedRestDays, setSelectedRestDays] = useState<
    IterableObject<boolean>
  >({});

  const { hr: hrOptions, min: minOptions } = useHrMinOptions();

  const postChangeRequestTemporarySchedule =
    usePostChangeRequestTemporarySchedule();
  const deleteChangeRequestSchedule = usePostChangeRequestSchedule();

  const temporaryChangeRequestError = useMemo(
    () => getRawCodeMessage(postChangeRequestTemporarySchedule.error),
    [postChangeRequestTemporarySchedule.error],
  );

  const range = useMemo(() => {
    return [
      dateFormStateMemoized.from
        ?.clone()
        .subtract(1, 'day')
        ?.format('YYYY-MM-DD'),
      dateFormStateMemoized.to?.clone().add(1, 'day')?.format('YYYY-MM-DD'),
    ];
  }, [dateFormStateMemoized.from, dateFormStateMemoized.to]);
  const rangeListDays = useMemo(() => {
    const sd = moment(range[0]);
    const ed = moment(range[1]);
    const days: Moment[] = [];
    while (sd.isSameOrBefore(ed)) {
      days.push(sd.clone());
      sd.add(1, 'day');
    }
    return days;
  }, [range]);

  const { data: dailySchedulesResult, isLoading: dailyScheduleIsLoading } =
    usePermanentTemporarySchedules(
      {
        key: 'temporary_schedule_checker',
        users: formState.user_id?.toString(),
        from_date: range[0],
        to_date: range[1],
      },
      {
        enabled: !!(
          formState.user_id &&
          dateFormStateMemoized.from &&
          dateFormStateMemoized.to
        ),
      },
    );

  const {
    data: userPermanentSchedules,
    isLoading: isLoadingUserPermanentSchedules,
  } = useUserPermanentSchedules(
    {
      key: 'temporary_change_request_item',
      users: formState.user_id?.toString()!,
      is_temporary: false,
    },
    {
      enabled: !!formState.user_id,
    },
  );

  const dayScheduleLocal = useMemo(() => {
    let daySchedule: IterableObject<DayScheduleLocal> = {};
    let userSchedule = userPermanentSchedules?.find(
      (u) => u.user === formState.user_id,
    );

    if (userSchedule) {
      userSchedule.schedules.forEach((schedule) => {
        daySchedule[moment(schedule.from_date).day()] = {
          id: schedule.id,
          is_temporary: schedule.is_temporary,
          start_time: schedule.start_time,
          end_time: schedule.end_time,
          start_time_local: moment
            .utc(schedule.start_date_time)
            .local()
            .format('hh:mm a'),
          end_time_local: moment
            .utc(schedule.end_date_time)
            .local()
            .format('hh:mm a'),
          weekday: schedule.weekday,
          tzinfo: schedule.tzinfo,
        };
      });
    }

    return daySchedule;
  }, [formState.user_id, userPermanentSchedules]);

  const getHrHmPeriodForm = (time?: string) => {
    const m = time ? moment(time, 'hh:mm a') : null;

    return {
      hour: m ? m.format('hh') : '',
      min: m ? m.format('mm') : '',
      period: m ? (m.format('a') as 'am' | 'pm') : 'am',
      initialHourOpt: hrOptions.find((opt) => opt.label === m?.format('hh')),
      initialMinOpt: minOptions.find((opt) => opt.label === m?.format('mm')),
    };
  };

  const getStartEndTime = () => {
    let {
      hour: fromHour,
      min: fromMin,
      period: fromPeriod,
    } = getHrHmPeriodForm(startForm.formState.time);
    let {
      hour: toHour,
      min: toMin,
      period: toPeriod,
    } = getHrHmPeriodForm(endForm.formState.time);

    return {
      start: startForm.formState.valid
        ? `${fromHour}:${fromMin} ${fromPeriod}`
        : '',
      end: endForm.formState.valid ? `${toHour}:${toMin} ${toPeriod}` : '',
    };
  };

  const getRangeOfSchedules = () => {
    const { from, to } = dateForm.formState;
    let dates: string[] = [];

    if (from && to) {
      const diff = moment(to).diff(from, 'day');

      for (let i = 0; i <= diff; i++) {
        let fromDate = moment(from).add(i, 'day');
        dates.push(fromDate.format('YYYY-MM-DD'));
      }
    }

    return dates;
  };

  const calculateTotalHoursLength = () => {
    let { hour: fromHour, min: fromMin } = getHrHmPeriodForm(
      startForm.formState.time,
    );
    let { hour: toHour, min: toMin } = getHrHmPeriodForm(
      endForm.formState.time,
    );

    if (
      !fromHour ||
      !fromMin ||
      !toHour ||
      !toMin ||
      !startForm.formState.valid ||
      !endForm.formState.valid
    ) {
      return {
        hours: 0,
        overlap: false,
      };
    }

    const { start, end } = getStartEndTime();
    return getTimeDifferenceBetweenStartAndEnd(start, end);
  };

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      updateFormState((state) => {
        const newState: FormState = { ...state };
        newState[name] = option ? (option as FieldSearchResponse).id : null;
        // Reset the requested_by when user changes, we don't allow own user
        // requesting for manager so he/she can approve himself/herself
        if (name === 'user_id') {
          newState.requested_by = '';
          newState.requested_by_role = null;
        }
        return newState;
      });

      /**
       * When user is changes. Reset the requested role input.
       */
      if (name === 'user_id') {
        updateFormKeyCount('requested_by_role');
      }
    };

  const handleOnTimePickerChange = ({
    start,
    startValid,
    end,
    endValid,
  }: {
    start: string;
    startValid: boolean;
    end: string;
    endValid: boolean;
  }) => {
    startForm.updateState((state) => ({
      ...state,
      time: start,
      valid: startValid,
    }));

    endForm.updateState((state) => ({
      ...state,
      time: end,
      valid: endValid,
    }));
  };

  const handleOnDatePickerChange = (from: string | null, to: string | null) => {
    dateForm.updateState((state) => ({
      ...state,
      from,
      to,
    }));
  };

  const handleOnSubmit = () => {
    const { start, end } = getStartEndTime();
    const payload: UsePostChangeRequestTemporarySchedulePayload = [];
    let datesToApply: string[] = Object.keys(selectedDates).sort();

    datesToApply.forEach((ymd: string) => {
      const m = moment(ymd);
      const selected = selectedDates[ymd];
      const pyDay = convertJsDayToPyDay(m.day());
      const startTime = moment(start, time12hrFormat).format(time24hrFormat);
      const endTime = moment(end, time12hrFormat).format(time24hrFormat);

      if (!selected && !selectedRestDays[ymd]) return;

      /**
       * Get the latest schedule of user it selected on the list of days,
       * by matching its schedule which already converted to local
       */
      const currentSchedule = dailySchedulesResult?.find(
        (ds) => ds.date === ymd,
      );
      const getRequestData = (
        latestSchedule?: DailyScheduleItem['schedules'][0],
        schedules?: DailyScheduleItem['schedules'],
      ): UsePostChangeRequestTemporarySchedulePayload[0] => {
        return {
          user: +formState.user_id!,
          reason: formState.reason,
          requested_by_role: formState.requested_by_role!,
          new_start_time: selected ? startTime : '00:00',
          new_end_time: selected ? endTime : '00:00',
          /**
           * Convert the weekday that needs to get overridden by this temporary schedule.
           */
          weekday: pyDay,
          from_date: ymd,
          to_date: ymd,
          tzinfo: timezone,
          metadata: latestSchedule
            ? {
                ...latestSchedule,
                // Attached a from/to date so we have some reference for preview
                from_date:
                  latestSchedule.from_date ??
                  moment
                    .utc(latestSchedule.start_date_time)
                    .tz(latestSchedule.tzinfo)
                    .format('YYYY-MM-DD'),
                to_date:
                  latestSchedule.to_date ??
                  moment
                    .utc(latestSchedule.end_date_time)
                    .tz(latestSchedule.tzinfo)
                    .format('YYYY-MM-DD'),
                schedules: schedules,
              }
            : undefined,
        };
      };

      /**
       * TODO: Not sure if we need to iterate it to the list of schedules,
       * or just the latest one. We are trying to solve in this case is the
       * multiple permanent schedule in local displayed where it has 2 or more
       * schedules due to the shift of date due to timezone
       */
      if (currentSchedule?.schedules.length) {
        const latestSchedule = getLatestProperLocalSchedules(
          currentSchedule?.schedules! as DayScheduleLocal[],
        );
        let filteredLatestSchedule = getUserFilteredDayLocalSchedules(
          { [ymd]: latestSchedule },
          {
            days: rangeListDays,
            range: range as string[],
            date: ymd,
          },
        );

        payload.push(
          getRequestData(
            filteredLatestSchedule[0] as unknown as DailyScheduleItem['schedules'][0],
            currentSchedule?.schedules,
          ),
        );
      } else {
        payload.push(getRequestData());
      }
    });

    postChangeRequestTemporarySchedule.mutate(payload);
  };

  const handleOnDeleteSchedule = () => {
    const payload: UsePostChangeRequestSchedulePayload = [];

    tempSchedulesAssociated?.forEach((ts) => {
      const jsDay = moment(ts.from_date).day();
      const pyDay = convertJsDayToPyDay(jsDay);
      const userPermSchedule = Object.values(dayScheduleLocal).find(
        (s) => s.weekday === pyDay,
      );

      const currentSchedule = dailySchedulesResult?.find(
        (ds) => ds.date === ts.from_date,
      );
      const prevSchedule = dailySchedulesResult?.find(
        (ds) =>
          ds.date ===
          moment(ts.from_date).subtract(1, 'day').format('YYYY-MM-DD'),
      );
      const nextSchedule = dailySchedulesResult?.find(
        (ds) =>
          ds.date === moment(ts.from_date).add(1, 'day').format('YYYY-MM-DD'),
      );

      payload.push({
        delete: true,
        schedule: ts.id,
        reason: formState.reason,
        requested_by_role: formState.requested_by_role!,
        tzinfo: ts.tzinfo,
        metadata: {
          schedules: currentSchedule?.schedules ?? [],
          prev_schedules: prevSchedule?.schedules ?? [],
          next_schedules: nextSchedule?.schedules ?? [],
          permanent_schedule: userPermSchedule
            ? {
                weekday: userPermSchedule?.weekday!,
                start_time: userPermSchedule?.start_time,
                end_time: userPermSchedule?.end_time,
                tzinfo: userPermSchedule?.tzinfo!,
              }
            : null,
        },
      });
    });

    if (payload.length) {
      deleteChangeRequestSchedule.mutate(payload);
    }
  };

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <CalendarMonthOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            {isDeleteOnly ? 'Delete ' : ''}Temporary Schedule
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderUserSelection = () => {
    if (isUserLock) {
      return null;
    }

    if (defaultUserMetadata) {
      return (
        <>
          <Box>
            <M3TextField
              name='user'
              label='User'
              fullWidth
              value={formState.user}
              disabled
            />
          </Box>
          <br />
        </>
      );
    }

    return (
      <>
        <M3Autocomplete
          key={formKeyCount.requester}
          options={requesterSearch.data ?? []}
          getOptionLabel={(option: any) =>
            getUserProfileDisplayName(option as UserMetadata).fullName ?? ''
          }
          loading={requesterSearch.isFetching}
          onChange={onAutocompleteChangeHandler('user_id')}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {
                getUserProfileDisplayName(option as unknown as UserMetadata)
                  .fullName
              }
            </li>
          )}
          renderInput={(params) => (
            <M3TextField
              {...params}
              name='user'
              label='User'
              fullWidth
              value={formState.user}
              onChange={handleChange}
            />
          )}
          sx={{
            flex: 1,
          }}
        />
        <br />
      </>
    );
  };

  const renderForm = () => {
    const { hours } = calculateTotalHoursLength();

    return (
      <>
        {renderUserSelection()}
        <Box
          flex={1}
          sx={{
            // pl: 1,
            '.MuiInputLabel-root': {
              top: '0 !important',
              left: '-6px !important',
              px: 0.8,
              background: isDarkMode
                ? 'var(--md-sys-color-background-dark) !important'
                : 'var(--md-sys-color-background-light) !important',
              '&.Mui-focused,&.MuiFormLabel-filled': {
                fontWeight: 'normal !important',
                transform: 'translate(14px, -10px) scale(0.8) !important',
              },
            },
          }}
        >
          <BasicDateRangePicker
            gap={3}
            direction='row'
            width='100%'
            columnSx={{
              borderRadius: 1,
              boxShadow: `0 0 0 1px ${
                isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral-variant70)'
              }`,
            }}
            textFieldSx={{
              '.MuiInputBase-input': {
                height: 42,
              },
            }}
            onChange={handleOnDatePickerChange}
            fromDatePickerProps={{
              disablePast: true,
            }}
            toDatePickerProps={{
              disablePast: true,
              disableFuture: false,
              minDate: dateFormStateMemoized.from || moment(),
            }}
          />
        </Box>
        <br />
        <TimePicker
          title='New Schedule'
          start={initialStartTime}
          end={initialEndTime}
          onChange={handleOnTimePickerChange}
        />
        <Typography component='div' pt={2} fontSize={13}>
          Allows{' '}
          <Typography
            component='span'
            fontSize={16}
            sx={{
              px: 0.1,
              py: 0.1,
              fontWeight: 700,
              borderRadius: 1,
            }}
          >
            {hours}
          </Typography>{' '}
          hour{hours > 1 ? 's' : ''} to complete an{' '}
          <Typography
            component='span'
            fontSize={16}
            sx={{
              px: 0.1,
              py: 0.1,
              fontWeight: 700,
              borderRadius: 1,
            }}
          >
            8
          </Typography>{' '}
          hour shift
        </Typography>
        <Typography
          gap={1}
          pt={0.5}
          fontSize={12}
          display='flex'
          component='div'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <ErrorOutlinedIcon
            style={{
              fontSize: 20,
              opacity: 0.49,
            }}
          />
          <span
            style={{
              opacity: 0.49,
            }}
          >
            Your length of shift should take into account breaks, etc.{' '}
            <i>
              (Some people have shorter/longer range of hours to complete their
              shift depending on the type of work you do.)
            </i>{' '}
            <br />
            For some of us an 8 hour shift is 8.5 hours long and some 12. If you
            are not sure... best to keep it tight.
          </span>
        </Typography>
      </>
    );
  };

  const renderRequestForm = () => {
    return (
      <>
        {!isUserLock && (
          <>
            <br />
            <Stack direction='row' gap={3}>
              <Box flex={1}>
                <M3Autocomplete
                  key={formKeyCount.requested_by_role}
                  options={requestByOptions}
                  value={
                    requestByOptions.find(
                      (opt) => opt.id === formState.requested_by_role,
                    ) ?? null
                  }
                  clearIcon={null}
                  getOptionLabel={(option: any) => option.label}
                  onChange={onAutocompleteChangeHandler('requested_by_role')}
                  renderInput={(params) => (
                    <M3TextField
                      {...params}
                      name='requested_by'
                      label='Requested by'
                      fullWidth
                      value={formState.requested_by}
                      onChange={handleChange}
                    />
                  )}
                  sx={{
                    flex: 1,
                  }}
                />
              </Box>
              <Box flex={1} />
            </Stack>
            <br />
          </>
        )}
        <Typography fontWeight={500} fontSize={18} component='div'>
          Reason
        </Typography>
        <Typography component='div' fontSize={14} sx={{ opacity: 0.5 }} pb={1}>
          If personal reasons discussed with manager don't go into unneeded
          detail, if it is for work reasons please be detailed and specific
        </Typography>
        <M3TextField
          name='reason'
          multiline
          minRows={5}
          fullWidth
          onChange={handleChange}
        />
        {/* {userReportToData && <Typography component='div' fontSize={14} pt={1}>
          <span
            style={{
              opacity: 0.5,
            }}
          >
            This has been approved by my direct manager at{' '}
          </span>
          <Typography component='span' fontSize={14}>
            {userReportToData.divis}
          </Typography>
        </Typography>} */}
      </>
    );
  };

  const renderSelectedDays = () => {
    const dates = getRangeOfSchedules();
    const { start, end } = getStartEndTime();
    const { overlap } = calculateTotalHoursLength();

    const startEndSx: SxProps = {
      width: 170,
      minWidth: 170,
    };
    return (
      <>
        <br />
        <Typography fontWeight={500} fontSize={18} component='div' mb={1}>
          Select all the days you will be working with this schedule
        </Typography>
        <Stack
          direction='row'
          alignItems='center'
          py={1.5}
          sx={{
            background: isDarkMode
              ? 'var(--md-ref-palette-primary20)'
              : 'var(--md-ref-palette-primary90)',
          }}
        >
          <Typography
            component='div'
            flex={1}
            width={0}
            pl={2}
            fontSize={14}
            fontWeight={500}
            sx={{
              color: isDarkMode
                ? 'var(--md-sys-color-on-surface-dark)'
                : 'var(--md-sys-color-on-surface-light)',
            }}
          >
            Date
          </Typography>
          <Typography
            component='div'
            fontSize={14}
            fontWeight={500}
            sx={{
              ...startEndSx,
              color: isDarkMode
                ? 'var(--md-sys-color-on-surface-dark)'
                : 'var(--md-sys-color-on-surface-light)',
            }}
          >
            Start of Shift
          </Typography>
          <Typography
            component='div'
            fontSize={14}
            fontWeight={500}
            sx={{
              ...startEndSx,
              color: isDarkMode
                ? 'var(--md-sys-color-on-surface-dark)'
                : 'var(--md-sys-color-on-surface-light)',
            }}
            pr={1}
          >
            Estimated End of Shift
          </Typography>
        </Stack>
        {dates.map((ymd, i) => {
          const isEven = i % 2 === 0;
          const selected = selectedDates[ymd] ?? false;
          const isRestDay = selectedRestDays[ymd] ?? false;
          let isConflict =
            temporaryChangeRequestError.parsed.originalError?.some(
              (item: any) => item?.conflict?.from_date === ymd,
            ) ||
            dailySchedulesResult?.some(
              (ds) =>
                ds.date === ymd && ds.schedules.some((s) => s.is_temporary),
            );

          return (
            <Stack
              key={ymd}
              py={0.5}
              direction='row'
              alignItems='center'
              className='noselect'
              position='relative'
              sx={{
                background: !isEven
                  ? isDarkMode
                    ? applyOpacityOnColor(
                        palette['md.ref.palette.neutral.variant40'],
                        0.1,
                      )
                    : applyOpacityOnColor(
                        palette['md.ref.palette.neutral.variant40'],
                        0.1,
                      )
                  : undefined,
                position: 'relative',
              }}
            >
              {isConflict && (
                <Tooltip
                  title='This will override the existing temporary schedule'
                  placement='left-start'
                >
                  <Box
                    style={{
                      top: 13,
                      right: 5,
                      position: 'absolute',
                    }}
                  >
                    <WarningAmberOutlinedIcon
                      style={{
                        fontSize: 20,
                        color: isDarkMode
                          ? 'var(--md-ref-palette-error80)'
                          : 'var(--md-ref-palette-error40)',
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              <Typography
                component='div'
                fontSize={14}
                flex={1}
                width={0}
                pl={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected}
                      onChange={(evt: SyntheticEvent) => {
                        setSelectedDates((state) => ({
                          ...state,
                          [ymd]: !selected,
                        }));
                      }}
                    />
                  }
                  label={moment(ymd).format('ddd, MMM D, YYYY')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: 14,
                    },
                  }}
                />
              </Typography>
              {!selected ? (
                <>
                  <Typography
                    component='div'
                    fontSize={14}
                    flex={1}
                    width={0}
                    pl={1.5}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isRestDay}
                          onChange={(evt: SyntheticEvent) => {
                            setSelectedRestDays((state) => ({
                              ...state,
                              [ymd]: !isRestDay,
                            }));
                          }}
                        />
                      }
                      label='Rest Day?'
                      sx={{
                        '.MuiTypography-root': {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Typography>
                  <Typography
                    component='div'
                    fontSize={14}
                    pr={1}
                    sx={{
                      ...startEndSx,
                    }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    component='div'
                    fontSize={14}
                    position='relative'
                    display='flex'
                    alignItems='center'
                    sx={{
                      ...startEndSx,
                    }}
                  >
                    {overlap && (
                      <NightsStayOutlinedIcon
                        style={{
                          opacity: 0.5,
                          fontSize: 16,
                          marginLeft: -20,
                          marginRight: 4,
                        }}
                      />
                    )}
                    {selected ? (
                      start || <span style={{ opacity: 0.5 }}>–</span>
                    ) : (
                      <span style={{ opacity: 0.5 }}>–</span>
                    )}
                  </Typography>
                  <Typography
                    component='div'
                    fontSize={14}
                    pr={1}
                    sx={{
                      ...startEndSx,
                    }}
                  >
                    {selected ? (
                      end || <span style={{ opacity: 0.5 }}>–</span>
                    ) : (
                      <span style={{ opacity: 0.5 }}>–</span>
                    )}
                  </Typography>
                </>
              )}
            </Stack>
          );
        })}
        <br />
      </>
    );
  };

  const renderBottomPanel = () => {
    const { hours } = calculateTotalHoursLength();

    let buttonDisabled =
      dailyScheduleIsLoading ||
      isLoadingUserPermanentSchedules ||
      postChangeRequestTemporarySchedule.isLoading ||
      (hours &&
      startForm.formState.valid &&
      endForm.formState.valid &&
      dateForm.formState.from &&
      dateForm.formState.to &&
      formState.user_id &&
      formState.requested_by_role &&
      formState.reason &&
      !!Object.values(selectedDates).filter((v) => !!v).length
        ? !(
            startForm.hasChanged ||
            endForm.hasChanged ||
            dateForm.hasChanged ||
            hasChanged
          )
        : true);

    if (isDeleteOnly) {
      buttonDisabled =
        dailyScheduleIsLoading ||
        isLoadingUserPermanentSchedules ||
        deleteChangeRequestSchedule.isLoading ||
        (formState.requested_by_role && formState.reason ? !hasChanged : true);
    }

    return (
      <Stack
        direction='row'
        sx={{ p: 1, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 90,
          }}
          disabled={buttonDisabled}
          onClick={isDeleteOnly ? handleOnDeleteSchedule : handleOnSubmit}
        >
          Send
        </M3Button>
      </Stack>
    );
  };

  const renderError = () => {
    if (!temporaryChangeRequestError.error) return null;

    return (
      <Typography
        pt={2}
        component='div'
        fontSize={14}
        style={{
          color: isDarkMode
            ? 'var(--md-ref-palette-error80)'
            : 'var(--md-ref-palette-error40)',
        }}
      >
        It appears there might be a conflict with the requested dates. Please
        review your selected time and dates for any potential overlaps.
      </Typography>
    );
  };

  useEffect(() => {
    const { from, to } = dateForm.formState;

    if (from && to && moment(from).isValid() && moment(to).isValid()) {
      setSelectedDates(() => {
        const state: IterableObject<boolean> = {};

        getRangeOfSchedules().forEach((ymd) => {
          const day = moment(ymd).day();

          if (day !== 0 && day !== 6) {
            state[ymd] = true;
          } else {
            state[ymd] = false;
          }
        });

        return state;
      });
    }
    // eslint-disable-next-line
  }, [dateForm.formState, setSelectedDates, setSelectedRestDays]);

  useEffect(() => {
    if (
      postChangeRequestTemporarySchedule.isSuccess ||
      deleteChangeRequestSchedule.isSuccess
    ) {
      close?.();
      enqueueSnackbar('Change request has been successfully sent.');
      updateActionKeyCounter('schedule_change_request_submitted');
    }
    // eslint-disable-next-line
  }, [
    postChangeRequestTemporarySchedule.isSuccess,
    deleteChangeRequestSchedule.isSuccess,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: [formState.user_id, userData?.reports_to_id],
    });
    // eslint-disable-next-line
  }, [userData, formState.user_id]);

  /**
   * When we lock user, we need to set the current logged in user as the user on the payload
   */
  useEffect(() => {
    if ((isDeleteOnly || isUserLock) && defaultUserMetadata) {
      updateFormState((state) => {
        state.user_id = defaultUserMetadata.id;
        state.user =
          getUserProfileDisplayName(defaultUserMetadata).fullName ?? '';

        if (isUserLock) {
          state.requested_by_role = 'teammate';
          state.requested_by = 'Teammate';
        }

        return state;
      });
    }
    // eslint-disable-next-line
  }, [isUserLock, isDeleteOnly, defaultUserMetadata]);

  let content = null;

  if (isDeleteOnly) {
    content = (
      <Box sx={{ pt: 3, pb: 0 }}>
        <Typography gap={1} pt={0.5} fontSize={16} component='div'>
          Are you sure you want to request to delete the schedule for{' '}
          {moment(initialLocalDate ?? initialFromDate).format('MMM D, YYYY')}?
        </Typography>
        <br />
        {isUserLock && <br />}
        <Box mb={-3}>{renderUserSelection()}</Box>
        {renderRequestForm()}
        <br />
      </Box>
    );
  } else {
    content = (
      <Box sx={{ pt: 3, pb: 0 }}>
        <Typography
          gap={1}
          pt={0.5}
          fontSize={13}
          component='div'
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-error60)'
              : 'var(--md-ref-palette-error50)',
          }}
        >
          This should only be used when you are still working a full shift, but
          your start and end time or days of the week will vary for UNDER two
          weeks. You need to file individual requests if different days have
          different start times. Only use this form when you have already
          received approval from your direct manager.
        </Typography>
        <br />
        {renderForm()}
        <br />
        <Divider />
        {renderSelectedDays()}
        <br />
        {renderRequestForm()}
        <br />
        {renderError()}
      </Box>
    );
  }

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
      sx={{
        maxWidth: 680,
      }}
    >
      {content}
    </ModalCardView>
  );
}

const TemporaryScheduleModalView = forwardRef(
  (props: TemporaryScheduleModalViewProps, ref) => (
    <TemporaryScheduleModalViewBase {...props} />
  ),
);

export default TemporaryScheduleModalView;
