import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Divider, Modal, Stack, SxProps } from '@mui/material';
import moment, { Moment } from 'moment';

import SetScheduleModalView from './SetScheduleModalView';
import TemporaryScheduleModalView from './TemporaryScheduleModalView';

import { useWorkspaceProvider } from '../../providers/workspace/workspace';
import { useUserProvider } from '../../providers/user/user';
import { getUserProfileDisplayName } from '../../utils/user';
import { UserMetadata, UserSearchItemResponse } from '../../types/profile';
import { IterableObject } from '../../types/types';
import { usePermanentTemporarySchedules } from '../../hooks/go2-schedules';
import { ScheduleItem } from '../../types/schedules';
import { escapeRegExp } from '../../utils/string';
import { useDebounceCallback } from '../../hooks/utils/misc';
import { useCurrentProfile, useUserSearch } from '../../hooks/profile';
import SearchUser from './ScheduleUserList/SearchUser';
import UserAvatar from './ScheduleUserList/UserAvatar';
import DayColumns from './ScheduleUserList/DayColumns';
import ScheduleCard, {
  DayPayload,
  getUserFilteredDayLocalSchedules,
} from './ScheduleUserList/ScheduleCard';
import ScrollArrow from './ScheduleUserList/ScrollArrow';

import './ScheduleUserList.css';
import { useAppProvider } from '../../providers/app/app';
import { useTick } from '../../hooks/utils/tick';
import { applyOpacityOnColor } from '../../utils/color';

type Props = {
  editable?: boolean;
  range: string[];
  changeRequestCounter?: number;
  onlyOwnUserEditable?: boolean;
  users?: UserMetadata[];
};

export type DayScheduleLocal = Partial<ScheduleItem> & {
  id: number;
  is_temporary: boolean;
  start_time: string;
  start_time_local: string;
  end_time: string;
  end_time_local: string;
};

const BASIC_COLUMN_HEIGHT = 68;

const ScheduleUserList = ({
  editable = true,
  range,
  changeRequestCounter,
  onlyOwnUserEditable,
  users: userListProps,
}: Props) => {
  const {
    isDarkMode,
    palettes: { main: palette },
  } = useAppProvider();
  const { items } = useWorkspaceProvider();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isTemporaryScheduleOpen, setIsTemporaryScheduleOpen] = useState(false);
  const { data: currentProfile } = useCurrentProfile();

  const [searchUserText, setSearchUserText] = useState('');
  const [userTextDebounce, setUserTextDebounce] = useState('');
  const setUserTextDebounceUpdate = useDebounceCallback((text: string) =>
    setUserTextDebounce(text),
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const arrowContainerRef = useRef<HTMLDivElement>(null);
  const arrowInnerRef = useRef<HTMLDivElement>(null);

  const days = useMemo(() => {
    const sd = moment(range[0]);
    const ed = moment(range[1]);
    const days: Moment[] = [];
    while (sd.isSameOrBefore(ed)) {
      days.push(sd.clone());
      sd.add(1, 'day');
    }
    return days;
  }, [range]);
  const [userSelected, setUserSelected] = useState<null | UserMetadata>(null);
  const [dayPayload, setDayPayload] = useState<null | DayPayload>(null);

  const [tempSchedulesAssociated, setTempSchedulesAssociated] = useState<
    DayScheduleLocal[]
  >([]);

  useTick();

  const mySquad = items.find((item) => item.id === 'my-squad')!;
  const mySquadUsers = useMemo(() => {
    return (
      mySquad?.memberId
        ?.split(',')
        .map((id) => getUser('employee_id', id))
        .filter((u) => !!u) ?? []
    );
    // eslint-disable-next-line
  }, [mySquad, users, getUser]);

  const searchSelect =
    (s: string) =>
    (data: UserSearchItemResponse[]): UserSearchItemResponse[] => {
      if (
        getUserProfileDisplayName(currentProfile!).fullName?.includes(
          s.toLowerCase(),
        ) ||
        currentProfile?.email.includes(s.toLowerCase())
      ) {
        data = [
          ...data,
          {
            id: `${currentProfile!.id}`,
            first_name: currentProfile!.first_name,
            last_name: currentProfile!.last_name,
            email: currentProfile!.email,
            photo_url: currentProfile!.photo_url,
            preferred_name: currentProfile!.preferred_name,
          },
        ];
      }

      return data;
    };
  const { data: searchUsersData } = useUserSearch(
    { s: userTextDebounce },
    {
      enabled: !userListProps && !!userTextDebounce,
      select: searchSelect(userTextDebounce) as () => UserSearchItemResponse[],
    },
  );
  const usersList: UserMetadata[] = useMemo(() => {
    let users = userListProps ?? (mySquadUsers.slice(0) as UserMetadata[]);

    if (userTextDebounce) {
      let regex = new RegExp(escapeRegExp(userTextDebounce), 'i');

      if (searchUsersData) {
        users = searchUsersData
          .map((u) => getUser('user_id', u.id))
          .filter((u) => !!u) as UserMetadata[];
      }

      users = users.filter((u) =>
        regex.test(getUserProfileDisplayName(u).fullName ?? ''),
      );
    }

    return users.filter((u) => !!u);
    // eslint-disable-next-line
  }, [userTextDebounce, userListProps, mySquadUsers, searchUsersData]);

  const { data: dailySchedulesResult, refetch: refetchDailySchedules } =
    usePermanentTemporarySchedules({
      users: usersList.map((u) => u!.id).join(','),
      from_date: moment(range[0]).subtract(1, 'day').format('YYYY-MM-DD'),
      to_date: moment(range[1]).add(1, 'day').format('YYYY-MM-DD'),
    });

  const scheduleGroupByUser = useMemo(() => {
    let group: IterableObject<IterableObject<DayScheduleLocal[]>> = {};

    if (!dailySchedulesResult?.length) return group;

    /**
     * Group the schedules by date.
     */
    dailySchedulesResult.forEach((ds) => {
      const userMap = group[ds.user] || {};
      const userGroupKey = ds.date;
      const userGroupDay = userMap[userGroupKey] || [];

      ds.schedules.forEach((schedule) => {
        /**
         * Avoid duplicate ids in a single day.
         */
        if (
          !userGroupDay.some(
            (groupSchedule) =>
              groupSchedule.id === schedule.id &&
              groupSchedule.date === schedule.date,
          )
        ) {
          userGroupDay.push({
            ...schedule,
            // TODO: Not sure commenting this is good... it might break elsewhere
            // from_date: schedule.from_date ?? ds.date,
            // to_date: schedule.to_date ?? ds.date,
            start_time_local: moment
              .utc(schedule.start_date_time)
              .local()
              .format('hh:mm a'),
            end_time_local: moment
              .utc(schedule.end_date_time)
              .local()
              .format('hh:mm a'),
          });
        }
      });

      // assigning it back
      userMap[userGroupKey] = userGroupDay;
      group[ds.user] = userMap;
    });

    return group;
  }, [dailySchedulesResult]);

  const firstColWidth = 260;
  const columnSx: SxProps = {
    minHeight: 80,
    minWidth: 160,
  };

  const currentScrollRef = scrollRef.current;
  const currentScrollWrapperEl = scrollWrapperRef.current;
  const arrowContainerEl = arrowContainerRef.current;
  const arrowInnerEl = arrowInnerRef.current;
  const { innerWidth } = window;

  const isLgScreen = innerWidth > 1640;

  const handleScroll = useCallback(() => {
    if (currentScrollRef && currentScrollWrapperEl) {
      const { scrollLeft, scrollWidth, clientWidth } = currentScrollRef;
      const atRightEnd = scrollLeft + clientWidth >= scrollWidth;
      const atLeftEnd = scrollLeft === 0;

      if (atRightEnd || isLgScreen) {
        currentScrollWrapperEl.classList.remove('after-active');
      } else {
        currentScrollWrapperEl.classList.add('after-active');
      }

      if (atLeftEnd || isLgScreen) {
        currentScrollWrapperEl.classList.remove('before-active');
      } else {
        currentScrollWrapperEl.classList.add('before-active');
      }
    }
  }, [currentScrollRef, currentScrollWrapperEl, isLgScreen]);

  const handleClickScroll = (direction: 'left' | 'right') => {
    if (currentScrollRef) {
      const offsetLeft = currentScrollRef.clientWidth * 0.5;
      if (direction === 'left') {
        currentScrollRef.scrollLeft -= offsetLeft;
      } else {
        currentScrollRef.scrollLeft += offsetLeft;
      }
    }
  };

  useEffect(() => {
    refetchDailySchedules();
  }, [changeRequestCounter, refetchDailySchedules]);

  useEffect(() => {
    setUserTextDebounceUpdate(searchUserText);
  }, [searchUserText, setUserTextDebounceUpdate]);

  useEffect(() => {
    if (!searchUsersData) return;

    setUniqueIdsToFetch({
      user_ids: searchUsersData.map((u) => +u.id),
    });
    // eslint-disable-next-line
  }, [searchUsersData]);

  useEffect(() => {
    const windowScrollHandler = () => {
      if (arrowContainerEl && arrowInnerEl) {
        const windowPositionY = window.scrollY * 0.98;

        arrowInnerEl.style.top = `${windowPositionY}px`;
      }
    };

    if (currentScrollRef && currentScrollWrapperEl) {
      currentScrollRef.addEventListener('scroll', handleScroll);
      currentScrollRef.addEventListener('resize', handleScroll);

      window.addEventListener('resize', handleScroll, false);
      window.addEventListener('scroll', windowScrollHandler, false);

      if (isLgScreen) {
        currentScrollWrapperEl.classList.remove(
          'before-active',
          'after-active',
        );
      }

      handleScroll();
      windowScrollHandler();
    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
        currentScrollRef.removeEventListener('resize', handleScroll);

        window.removeEventListener('scroll', windowScrollHandler, false);
        window.removeEventListener('resize', windowScrollHandler, false);
      }
    };
  }, [
    currentScrollRef,
    currentScrollWrapperEl,
    arrowContainerEl,
    arrowInnerEl,
    handleScroll,
    isLgScreen,
  ]);

  return (
    <Box>
      <Box display='flex' width='100%' position='relative'>
        <Stack direction='column' mt={1}>
          <SearchUser
            firstColWidth={firstColWidth}
            searchUserText={searchUserText}
            setSearchUserText={setSearchUserText}
          />
          <Divider />
          {usersList.map((user, i) => {
            const userSchedules = scheduleGroupByUser[user.id] ?? {};
            const firstColHeight = calculateColumnHeight(userSchedules, {
              days,
              range,
            });

            return (
              <Fragment key={user.id}>
                {i !== 0 && <Divider />}
                <UserAvatar
                  firstColHeight={firstColHeight}
                  firstColWidth={firstColWidth}
                  user={user}
                  days={days}
                />
              </Fragment>
            );
          })}
          <Divider />
        </Stack>
        <Box
          ref={scrollWrapperRef}
          flex={1}
          width={0}
          className='schedule-days-list-container'
        >
          <div
            className='schedule-days-list-container-before'
            style={{
              background: `linear-gradient(
                to right,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  1,
                )} 0%,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  0,
                )} 100%
              )`,
            }}
          ></div>
          <Box
            ref={scrollRef}
            sx={
              !isLgScreen
                ? {
                    overflow: 'scroll',
                    scrollBehavior: 'smooth',
                    width: isLgScreen ? '100%' : 'auto',
                    '&::-webkit-scrollbar': {
                      outline: 'none',
                      height: 0,
                      width: 0,
                    },
                  }
                : null
            }
          >
            <Box width={isLgScreen ? '100%' : 'max-content'}>
              <Stack direction='column'>
                <DayColumns columnSx={columnSx} days={days} range={range} />
                <Divider />
                {usersList.map((user, i) => {
                  const userSchedules = scheduleGroupByUser[user.id] ?? {};
                  let columnHeight = calculateColumnHeight(userSchedules, {
                    days,
                    range,
                  });

                  return (
                    <Fragment key={user.id}>
                      <ScheduleCard
                        columnSx={{
                          ...columnSx,
                          height: columnHeight,
                        }}
                        days={days}
                        range={range}
                        editable={editable}
                        onlyOwnUserEditable={onlyOwnUserEditable}
                        setDayPayload={setDayPayload}
                        setIsScheduleOpen={setIsScheduleOpen}
                        setIsTemporaryScheduleOpen={setIsTemporaryScheduleOpen}
                        setTempSchedulesAssociated={setTempSchedulesAssociated}
                        setUserSelected={setUserSelected}
                        user={user}
                        userSchedules={userSchedules}
                      />
                      <Divider />
                    </Fragment>
                  );
                })}
              </Stack>
            </Box>
          </Box>
          <div
            className='schedule-days-list-container-after'
            style={{
              background: `linear-gradient(
                to right,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  0,
                )} 0%,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  1,
                )} 100%
              )`,
            }}
          ></div>
          <Box
            ref={arrowContainerRef}
            top={0}
            left={0}
            right={0}
            position='absolute'
          >
            <Box
              ref={arrowInnerRef}
              px={1}
              top={0}
              left={0}
              right={0}
              zIndex={2}
              display='flex'
              position='absolute'
              alignItems='center'
              justifyContent='space-between'
              sx={{
                minHeight: `110px`,
                pointerEvents: 'none',
              }}
            >
              <ScrollArrow
                direction='left'
                isLgScreen={isLgScreen}
                onClick={() => handleClickScroll('left')}
              />
              <ScrollArrow
                direction='right'
                isLgScreen={isLgScreen}
                onClick={() => handleClickScroll('right')}
              />
            </Box>
          </Box>
        </Box>
        {!!usersList.length && <Divider />}
        <Modal open={isScheduleOpen}>
          <SetScheduleModalView
            {...dayPayload}
            user={userSelected!}
            isUserLock={onlyOwnUserEditable}
            close={() => {
              setIsScheduleOpen(false);
              refetchDailySchedules();
            }}
          />
        </Modal>
        <Modal open={isTemporaryScheduleOpen}>
          <TemporaryScheduleModalView
            {...dayPayload}
            user={userSelected!}
            isUserLock={onlyOwnUserEditable}
            isDeleteOnly
            tempSchedulesAssociated={tempSchedulesAssociated}
            close={() => {
              setIsTemporaryScheduleOpen(false);
              refetchDailySchedules();
            }}
          />
        </Modal>
      </Box>
    </Box>
  );
};

export default ScheduleUserList;

type CalculateColumnHeightOpt = {
  days: Moment[];
  range: string[];
};
function calculateColumnHeight(
  userSchedules: IterableObject<DayScheduleLocal[]>,
  opt: CalculateColumnHeightOpt,
) {
  const { days } = opt ?? {};
  const gap = 8;
  const paddingY = 6.4 * 2;

  /**
   * When calculating the proper column height, we need to iterate through
   * all the schedules, and get the largest filtered schedules in a day.
   * so we can get the maximum number of entries on a day.
   */
  let largestFilteredSchedules: DayScheduleLocal[] = [];
  days.forEach((m) => {
    const date = m.format('YYYY-MM-DD');
    let filteredChosenDaySchedules = getUserFilteredDayLocalSchedules(
      userSchedules,
      { ...opt, date },
    );

    largestFilteredSchedules =
      filteredChosenDaySchedules.length > largestFilteredSchedules.length
        ? filteredChosenDaySchedules
        : largestFilteredSchedules;
  });

  const totalSchedule = largestFilteredSchedules.length || 1;
  const totalGap = (totalSchedule - 1) * gap;

  return totalSchedule * BASIC_COLUMN_HEIGHT + totalGap + paddingY;
}
