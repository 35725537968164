import { Stack, Typography, Box, Avatar, Divider, Badge } from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../Dialogs/ConfirmationDialog';
import { useForm } from '../BasicForm';
import TimePicker, { useHrMinOptions } from '../TimePicker/TimePicker';
import { M3TextField } from '../M3/M3TextField';
import { M3Autocomplete } from '../M3/M3Autocomplete';
import { DayScheduleLocal } from './ScheduleUserList';

import { UserMetadata } from '../../types/profile';
import { getUserInitials, getUserPhotoUrl } from '../../utils/user';
import { useAppProvider } from '../../providers/app/app';
import { IterableObject } from '../../types/types';
import { getTimeDifferenceBetweenStartAndEnd } from '../../utils/date';
import {
  UsePostChangeRequestSchedulePayload,
  UsePostSchedulePayload,
  usePostChangeRequestSchedule,
  usePostSchedule,
  useUserPermanentSchedules,
} from '../../hooks/go2-schedules';
import { convertJsDayToPyDay, convertPyToJsDay } from '../../utils/schedule';
import { useCurrentProfile } from '../../hooks/profile';
import { useTimezone } from '../Util/Timezone';

type SetScheduleModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    user?: UserMetadata;
    day?: number;
    end?: string;
    start?: string;
    isNew?: boolean;
    scheduleId?: number;
    isTemporary?: boolean;
    isUserLock?: boolean;
  };

type TimeValidFormState = {
  time?: string;
  valid: boolean;
};

type OthersFormState = {
  reason: string;
  requested_by: string;
  requested_by_role: 'manager' | 'teammate' | null;
};

export function SetScheduleModalViewBase({
  isNew,
  day,
  start: initialStartTime,
  end: initialEndTime,
  user,
  close,
  isUserLock,
  isTemporary,
  scheduleId,
}: SetScheduleModalViewProps) {
  const { isDarkMode, updateActionKeyCounter } = useAppProvider();
  const time12hrFormat = 'hh:mm a';
  const time24hrFormat = 'HH:mm';

  const confirmationDialog = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { data: currentProfile } = useCurrentProfile();

  const [days] = useState(() =>
    [1, 2, 3, 4, 5, 6, 7].map((d) => moment().day(d)),
  );
  const [selectedDay] = useState(() => moment().day(day!));

  const [applyAlsoToDays, setApplyAlsoToDays] = useState<
    IterableObject<boolean>
  >({});

  const startForm = useForm<TimeValidFormState>({
    time: initialStartTime,
    valid: !!initialStartTime,
  });

  const endForm = useForm<TimeValidFormState>({
    time: initialEndTime,
    valid: !!initialEndTime,
  });

  const {
    formState: othersForm,
    hasChanged,
    handleChange,
    updateState: updateFormState,
    updateFormKeyCount,
  } = useForm<OthersFormState>(
    {
      reason: '',
      requested_by: '',
      requested_by_role: null,
    },
    {
      onStateUpdated(state, changed) {
        if ('requested_by_role' in (changed ?? {})) {
          updateFormKeyCount('requested_by_role');
        }
      },
    },
  );

  const { hr: hrOptions, min: minOptions } = useHrMinOptions();

  const requestByOptions = useMemo(() => {
    const opts = [
      {
        id: 'teammate',
        label: 'Teammate',
      },
    ];

    if (user && user.id !== currentProfile!.id) {
      opts.push({
        id: 'manager',
        label: 'Manager',
      });
    }

    return opts;
  }, [user, currentProfile]);

  const postSchedule = usePostSchedule();
  const postChangeRequestSchedule = usePostChangeRequestSchedule();
  const deleteChangeRequestSchedule = usePostChangeRequestSchedule();

  const timezone = useTimezone();

  const { data: userPermanentSchedules } = useUserPermanentSchedules(
    {
      users: user!.id.toString(),
      is_temporary: false,
    },
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const dayScheduleLocal = useMemo(() => {
    let daySchedule: IterableObject<DayScheduleLocal> = {};
    let userSchedule = userPermanentSchedules?.find((u) => u.user === user?.id);

    if (userSchedule) {
      userSchedule.schedules.forEach((schedule) => {
        daySchedule[moment(schedule.from_date).day()] = {
          id: schedule.id,
          is_temporary: schedule.is_temporary,
          start_time: schedule.start_time,
          end_time: schedule.end_time,
          start_time_local: moment
            .utc(schedule.start_date_time)
            .local()
            .format('hh:mm a'),
          end_time_local: moment
            .utc(schedule.end_date_time)
            .local()
            .format('hh:mm a'),
          weekday: schedule.weekday,
          tzinfo: schedule.tzinfo,
        };
      });
    }

    return daySchedule;
  }, [user, userPermanentSchedules]);

  const isNewAndHasExisting = isNew && !!Object.keys(dayScheduleLocal).length;

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      handleChange({
        target: {
          name,
          value: option ? option.id : null,
        },
      });
    };

  const getHrHmPeriodForm = (time?: string) => {
    const m = time ? moment(time, time12hrFormat) : null;

    return {
      hour: m ? m.format('hh') : '',
      min: m ? m.format('mm') : '',
      period: m ? (m.format('a') as 'am' | 'pm') : 'am',
      initialHourOpt: hrOptions.find((opt) => opt.label === m?.format('hh')),
      initialMinOpt: minOptions.find((opt) => opt.label === m?.format('mm')),
    };
  };

  const getStartEndTime = () => {
    let {
      hour: fromHour,
      min: fromMin,
      period: fromPeriod,
    } = getHrHmPeriodForm(startForm.formState.time);
    let {
      hour: toHour,
      min: toMin,
      period: toPeriod,
    } = getHrHmPeriodForm(endForm.formState.time);

    return {
      start: `${fromHour}:${fromMin} ${fromPeriod}`,
      end: `${toHour}:${toMin} ${toPeriod}`,
    };
  };

  const calculateTotalHoursLength = () => {
    let { hour: fromHour, min: fromMin } = getHrHmPeriodForm(
      startForm.formState.time,
    );
    let { hour: toHour, min: toMin } = getHrHmPeriodForm(
      endForm.formState.time,
    );

    if (
      !fromHour ||
      !fromMin ||
      !toHour ||
      !toMin ||
      !startForm.formState.valid ||
      !endForm.formState.valid
    ) {
      return {
        hours: 0,
        overlap: false,
      };
    }

    const { start, end } = getStartEndTime();
    return getTimeDifferenceBetweenStartAndEnd(start, end);
  };

  const handleOnApplyAlsoToDays = (day: number) => () => {
    setApplyAlsoToDays((state) => ({ ...state, [day]: !state[day] }));
  };

  const handleOnTimePickerChange = ({
    start,
    startValid,
    end,
    endValid,
  }: {
    start: string;
    startValid: boolean;
    end: string;
    endValid: boolean;
  }) => {
    startForm.updateState((state) => ({
      ...state,
      time: start,
      valid: startValid,
    }));

    endForm.updateState((state) => ({
      ...state,
      time: end,
      valid: endValid,
    }));
  };

  const handleOnSubmit = () => {
    const { start, end } = getStartEndTime();
    const payload: UsePostSchedulePayload = [];
    let daysToApply: number[] = [
      day!,
      ...Object.keys(applyAlsoToDays)
        .filter((d) => applyAlsoToDays[d])
        .map((d) => +d),
    ];
    /**
     * Before sending to the server, let's convert the days selected compatible to python days
     */
    daysToApply = daysToApply.map(convertJsDayToPyDay).sort();
    /**
     * Convert the day to its corresponding UTC equivalent
     */

    daysToApply.forEach((pyDay: number) => {
      const startTime = moment(start, time12hrFormat)
        .day(pyDay)
        .format(time24hrFormat);
      const endTime = moment(end, time12hrFormat)
        .day(pyDay)
        .format(time24hrFormat);

      payload.push({
        weekday: pyDay,
        start_time: startTime,
        end_time: endTime,
        user: user!.id,
        tzinfo: timezone,
      });
    });

    postSchedule.mutate(payload);
  };

  const handleRequestChangeSubmit = () => {
    const { start, end } = getStartEndTime();
    const payload: UsePostChangeRequestSchedulePayload = [];
    let daysToApply: number[] = [
      day!,
      ...Object.keys(applyAlsoToDays)
        .filter((d) => applyAlsoToDays[d])
        .map((d) => +d),
    ];
    /**
     * Before sending to the server, let's convert the days selected compatible
     * to python days
     */
    daysToApply = daysToApply.map(convertJsDayToPyDay).sort();
    /**
     * Convert the day to its corresponding UTC equivalent
     */
    daysToApply.forEach((pyDay: number) => {
      const currentLocalSchedule = dayScheduleLocal[convertPyToJsDay(pyDay)];
      const startTime = moment(start, time12hrFormat).format(time24hrFormat);
      const endTime = moment(end, time12hrFormat).format(time24hrFormat);

      payload.push({
        schedule: currentLocalSchedule.id,
        new_start_time: startTime,
        new_end_time: endTime,
        reason: othersForm.reason,
        requested_by_role: othersForm.requested_by_role!,
        tzinfo: timezone,
        weekday: pyDay,
        metadata: {
          weekday: currentLocalSchedule.weekday!,
          start_time: currentLocalSchedule.start_time,
          end_time: currentLocalSchedule.end_time,
          tzinfo: currentLocalSchedule.tzinfo ?? timezone,
        },
      });
    });

    postChangeRequestSchedule.mutate(payload);
  };

  const handleOnDeleteSchedule = () => {
    const payload: UsePostChangeRequestSchedulePayload = [];
    const currentLocalSchedule = dayScheduleLocal[day!];

    payload.push({
      schedule: scheduleId!,
      reason: othersForm.reason,
      requested_by_role: othersForm.requested_by_role!,
      delete: true,
      tzinfo: timezone,
      weekday: convertJsDayToPyDay(day!),
      metadata: {
        weekday: currentLocalSchedule.weekday!,
        start_time: currentLocalSchedule.start_time,
        end_time: currentLocalSchedule.end_time,
        tzinfo: currentLocalSchedule.tzinfo ?? timezone,
      },
    });

    deleteChangeRequestSchedule.mutate(payload);
  };

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <CalendarMonthOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            {isNew ? 'Set Schedule' : 'Change Request Schedule'}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderUserProfile = () => {
    return (
      <Stack flexDirection='row' justifyContent='space-between'>
        <Stack gap={2} flexDirection='row' position='relative' flex={1}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {getUserInitials(user?.first_name).initial}
          </Avatar>
          <Box>
            <Typography component='div' fontSize={20} fontWeight={500}>
              {user?.first_name} {user?.last_name}
            </Typography>
            <Typography component='div' fontSize={12} sx={{ opacity: 0.5 }}>
              {isNew
                ? 'No schedule set'
                : `Current Schedule: ${initialStartTime} - ${initialEndTime}`}
            </Typography>
          </Box>
        </Stack>
        {/* {!isNew && (
          <Box textAlign='right' pt={1}>
            <Typography component='div' fontSize={12} sx={{ opacity: 0.5 }}>
              Last updated by:
            </Typography>
            <Typography component='div' fontSize={14}>
              {user?.first_name} {user?.last_name}
            </Typography>
          </Box>
        )} */}
      </Stack>
    );
  };

  const renderForm = () => {
    const { hours } = calculateTotalHoursLength();

    return (
      <>
        <TimePicker
          title={selectedDay.format('dddd')}
          start={initialStartTime}
          end={initialEndTime}
          onChange={handleOnTimePickerChange}
        />
        <Typography component='div' pt={2} fontSize={13}>
          Allows{' '}
          <Typography
            component='span'
            fontSize={16}
            sx={{
              px: 0.1,
              py: 0.1,
              fontWeight: 700,
              borderRadius: 1,
            }}
          >
            {hours}
          </Typography>{' '}
          hour{hours > 1 ? 's' : ''} to complete an{' '}
          <Typography
            component='span'
            fontSize={16}
            sx={{
              px: 0.1,
              py: 0.1,
              fontWeight: 700,
              borderRadius: 1,
            }}
          >
            8
          </Typography>{' '}
          hour shift
        </Typography>
        <Typography
          gap={1}
          pt={0.5}
          fontSize={12}
          display='flex'
          component='div'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <ErrorOutlinedIcon
            style={{
              fontSize: 20,
              opacity: 0.49,
            }}
          />
          <span
            style={{
              opacity: 0.49,
            }}
          >
            Your length of shift should take into account breaks, etc.{' '}
            <i>
              (Some people have shorter/longer range of hours to complete their
              shift depending on the type of work you do.)
            </i>{' '}
            <br />
            For some of us an 8 hour shift is 8.5 hours long and some 12. If you
            are not sure... best to keep it tight.
          </span>
        </Typography>
        <br />
        <Divider />
        <Typography
          gap={1}
          pt={1}
          fontSize={14}
          component='div'
          display='flex'
          alignItems='center'
        >
          Apply these changes also to:
          <Stack
            gap={1}
            direction='row'
            style={{
              paddingTop: 8,
            }}
          >
            {days.map((m, i) => {
              const sameDay = selectedDay.day() === m.day();
              const checked = applyAlsoToDays[m.day()];
              const scheduleLocal = dayScheduleLocal[m.day()];
              const disabled = isNew
                ? isNewAndHasExisting
                  ? !!scheduleLocal
                  : false
                : !scheduleLocal;

              return (
                <Box
                  key={`${i}_${checked}`}
                  onClick={
                    sameDay || disabled
                      ? undefined
                      : handleOnApplyAlsoToDays(m.day())
                  }
                  sx={{
                    cursor: sameDay || disabled ? undefined : 'pointer',
                  }}
                >
                  <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                      (checked || sameDay) && (
                        <Avatar
                          sx={{
                            top: 1,
                            left: 1,
                            width: 16,
                            height: 16,
                            position: 'relative',
                            pointerEvents: 'none',
                          }}
                          style={{
                            background: isDarkMode
                              ? 'var(--md-sys-color-background-dark)'
                              : 'var(--md-sys-color-background-light)',
                          }}
                        >
                          <CheckOutlinedIcon
                            style={{
                              top: 0,
                              left: 0,
                              fontSize: 14,
                              position: 'relative',
                            }}
                          />
                        </Avatar>
                      )
                    }
                  >
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        fontSize: 12,
                        fontWeight: 500,
                        opacity: sameDay || disabled ? 0.29 : undefined,
                      }}
                    >
                      {m.format('dd')}
                    </Avatar>
                  </Badge>
                </Box>
              );
            })}
          </Stack>
        </Typography>
        <Typography
          component='div'
          gap={1}
          pt={0.5}
          pl={23}
          fontSize={12}
          display='flex'
          justifyContent='flex-start'
          alignItems='flex-start'
          style={{
            opacity: 0.49,
          }}
        >
          {isNew
            ? 'Changes can only be applied to the days that do not have a set schedule'
            : 'Change requests can only be applied to days with a schedule'}
        </Typography>
        <br />
        {!isNew && renderReason()}
        <br />
        <br />
        {!isNew && (
          <M3Button
            sx={{
              ml: -1.4,
              mb: -2,
            }}
            onClick={() => confirmationDialog.confirm.setIsOpen(true)}
          >
            <DeleteOutlinedIcon /> Delete Schedule&nbsp;
          </M3Button>
        )}
      </>
    );
  };

  const renderRequestedBy = () => {
    if (isUserLock) {
      return null;
    }

    return (
      <>
        <br />
        <Stack direction='row' gap={3}>
          <Box flex={1}>
            <M3Autocomplete
              options={requestByOptions}
              value={
                requestByOptions.find(
                  (opt) => opt.id === othersForm.requested_by_role,
                ) ?? null
              }
              clearIcon={null}
              getOptionLabel={(option: any) => option.label}
              onChange={onAutocompleteChangeHandler('requested_by_role')}
              renderInput={(params) => (
                <M3TextField
                  {...params}
                  name='requested_by'
                  label='Requested by'
                  fullWidth
                  value={othersForm.requested_by}
                  onChange={handleChange}
                />
              )}
              sx={{
                flex: 1,
              }}
            />
          </Box>
          <Box flex={1} />
        </Stack>
        <br />
      </>
    );
  };

  const renderReason = () => {
    return (
      <>
        {renderRequestedBy()}
        <Typography fontWeight={500} fontSize={18} component='div'>
          Reason
        </Typography>
        <Typography component='div' fontSize={14} sx={{ opacity: 0.5 }} pb={1}>
          If personal reasons discussed with manager don't go into unneeded
          detail, if it is for work reasons please be detailed and specific
        </Typography>
        <M3TextField
          name='reason'
          multiline
          minRows={5}
          fullWidth
          value={othersForm.reason}
          onChange={handleChange}
        />
      </>
    );
  };

  const renderBottomPanel = () => {
    const { hours } = calculateTotalHoursLength();
    const { start, end } = getStartEndTime();
    let buttonDisabled = true;

    if (isNew) {
      buttonDisabled =
        postSchedule.isLoading ||
        (hours && startForm.formState.valid && endForm.formState.valid
          ? !(startForm.hasChanged || endForm.hasChanged || hasChanged)
          : true);
    } else {
      buttonDisabled =
        postChangeRequestSchedule.isLoading ||
        (hours &&
        startForm.formState.valid &&
        endForm.formState.valid &&
        othersForm.requested_by_role &&
        othersForm.reason &&
        (initialStartTime !== start || initialEndTime !== end)
          ? !(startForm.hasChanged || endForm.hasChanged || hasChanged)
          : true);
    }

    return (
      <Stack
        direction='row'
        sx={{ p: 1, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 90,
          }}
          disabled={buttonDisabled}
          onClick={isNew ? handleOnSubmit : handleRequestChangeSubmit}
        >
          {isNew ? 'Save' : 'Submit'}
        </M3Button>
      </Stack>
    );
  };

  useEffect(() => {
    if (
      postSchedule.isSuccess ||
      postChangeRequestSchedule.isSuccess ||
      deleteChangeRequestSchedule.isSuccess
    ) {
      close?.();
      updateActionKeyCounter('schedule_change_request_submitted');
    }
    if (postChangeRequestSchedule.isSuccess) {
      enqueueSnackbar('Change request has been successfully sent.');
    }
    // eslint-disable-next-line
  }, [
    postSchedule.isSuccess,
    postChangeRequestSchedule.isSuccess,
    deleteChangeRequestSchedule.isSuccess,
    enqueueSnackbar,
  ]);

  /**
   * When we lock user, we need to set the current logged in user as the user on the payload
   */
  useEffect(() => {
    if (isUserLock) {
      updateFormState((state) => {
        state.requested_by_role = 'teammate';
        state.requested_by = 'Teammate';
        return state;
      });
    }
    // eslint-disable-next-line
  }, [isUserLock]);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
      sx={{
        maxWidth: 680,
      }}
    >
      <Box sx={{ pt: 3, pb: 0 }}>
        {renderUserProfile()}
        <br />
        {renderForm()}
      </Box>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title='Delete Schedule'
        message={
          <>
            Are you sure you want to request to delete this schedule?
            <Box pt={isUserLock ? 2 : 0} mb={-2}>
              {renderReason()}
            </Box>
          </>
        }
        onConfirm={handleOnDeleteSchedule}
        confirmDisabled={!(othersForm.reason && othersForm.requested_by_role)}
        isLoading={deleteChangeRequestSchedule.isLoading}
        {...({
          PaperProps: {
            sx: {
              maxWidth: 480,
            },
          },
        } as any)}
      />
    </ModalCardView>
  );
}

const SetScheduleModalView = forwardRef(
  (props: SetScheduleModalViewProps, ref) => (
    <SetScheduleModalViewBase {...props} />
  ),
);

export default SetScheduleModalView;
